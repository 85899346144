import React from 'react';

export interface IArrivalCardProps {
    flightId: string;
    airlineOperator: string;
    arrivalTime: string;
    departureAirportSwedish: string;
    terminal: string;
    flightLegStatusSwedish: string;
}

export default function ArrivalCard(props: IArrivalCardProps) {
    return (
        <div className="border border-slate-950 lg:border-2 lg:text-3xl rounded my-2 hover:bg-slate-100">
            <div className="flex justify-between"><div>Flyg: {props.flightId}</div><div>Terminal: {props.terminal}</div></div>
            <div>Ankomst: {props.arrivalTime.split('T')[1].substring(0, 5)}</div>
        </div>);
}