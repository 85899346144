import { useState, useEffect } from "react";
import IFlightInfo from "./IFlightInfo";

export default function useDataFetch(date: string): IFlightInfo {
  const [data, setData] = useState<IFlightInfo>();
  useEffect(() => {
    const endpoint = `https://kollaflygetnu-api.azurewebsites.net/api/data/${date}`;
    fetch(endpoint)
      .then((response) => {
        if (!response.ok)
          console.log(`${endpoint}: ${response.statusText}`);

        return response.json();
      }).then((data: IFlightInfo) => setData(data));
  }, [date]);

  return data as IFlightInfo;
}