import React, { useState } from "react";
import "./App.css";
import ArrivalCard from "./presentation/ArrivalCard.tsx";
import useDataFetch from "./useDataFetch.tsx";

function App() {
  const [selectedDate] = useState(new Date().toISOString().split('T')[0]);
  const arrivals = useDataFetch(selectedDate);

  return (
    <div className='lg:w-1/3 mx-auto h-screen flex flex-col'>
      <div className="flex-grow overflow-auto px-1 lg:pr-1">
        <h1 className="lg:text-3xl">Ankomster Terminal 5</h1>
        <ul>
          {
            arrivals?.flights.map((flight, index) => {
              return (
                <li key={index}>
                  <ArrivalCard
                    flightId={flight.flightId}
                    airlineOperator={flight.airlineOperator.name}
                    arrivalTime={flight.arrivalTime.scheduledUtc}
                    departureAirportSwedish={flight.departureAirportSwedish}
                    terminal={flight.locationAndStatus.terminal}
                    flightLegStatusSwedish={flight.locationAndStatus.flightLegStatusSwedish}
                  />
                </li>
              );
            })
          }
        </ul>
      </div>
      <hr className="my-2" />
      {/* <div className="flex gap-2">
        <input type="button" value={"Idag"} className="rounded border border-slate-950 lg:border-2 lg:text-3xl grow py-2 hover:bg-slate-100" />
        <input type="button" value={"Imorgon"} className="rounded border border-slate-950 lg:border-2 lg:text-3xl grow py-2 hover:bg-slate-100" />
      </div> */}
    </div >
  );
}

export default App;
